import {
  createContext, useMemo, useReducer, useCallback
} from "react";
import { User, Cowfarmer } from "./AuthInterface";
import { authReducer, AuthState } from "./AuthReducer";
import { Rol, AuthenticationResult } from "../services/master/interface";
import { paths } from "../routes/paths";
import { useNavigate } from "react-router-dom";

type AuthContextProps = {
  errorMessage: string;
  token: string | null;
  user: User | null;
  cowfarmer: Cowfarmer | null;
  cowbarn: number;
  roles: Rol | null;
  setCowbarn: (cowbarn: number) => void;
  status: "checking" | "authenticated" | "not-authenticated";
  signIn: (
    idToken: string,
    cowfarmer: Cowfarmer,
    user: User,
    roles: Rol,
    authenticationResult: AuthenticationResult
  ) => void;
  logOut: () => void;
  acceptTerms: () => void;
};

const authInitialState: AuthState = {
  status:       "checking",
  token:        null,
  user:         null,
  cowfarmer:    null,
  cowbarn:      0,
  errorMessage: "",
  roles:        null
};

const init = (): AuthState => {
  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user") ?? "")
    : null;
  const cowfarmer = localStorage.getItem("cowfarmer")
    ? JSON.parse(localStorage.getItem("cowfarmer") ?? "")
    : null;
  const token = localStorage.getItem("idToken");
  const roles = localStorage.getItem("roles")
    ? JSON.parse(localStorage.getItem("roles") ?? "")
    : null;

  return {
    status:       user ? "authenticated" : "not-authenticated",
    token:        token ?? null,
    user:         user ?? null,
    cowfarmer:    cowfarmer ?? null,
    cowbarn:      0,
    errorMessage: "",
    roles:        roles || null
  };
};

export const AuthContext = createContext({} as AuthContextProps);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, authInitialState, init);
  const navigate = useNavigate();

  const signIn = useCallback((
    idToken: string,
    cowfarmer: Cowfarmer,
    user: User,
    roles: Rol,
    authenticationResult: AuthenticationResult
  ) => {
    localStorage.setItem("idToken", idToken);
    localStorage.setItem("cowfarmer", JSON.stringify(cowfarmer));
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("roles", JSON.stringify(roles));
    localStorage.setItem("refresh", authenticationResult.RefreshToken);

    if(user.condition_1 && user.condition_2){
      navigate(paths.home);
    } else {
      navigate(paths.termsAndConditions);
    }

    dispatch({
      type:    "signIn",
      payload: {
        token:     idToken,
        user:      user,
        cowfarmer: cowfarmer,
        roles:     roles
      }
    });
  }, [navigate]);

  const setCowbarn = useCallback((cowbarn: number) => {
    dispatch({
      type:    "setCowbarn",
      payload: {
        cowbarn: cowbarn
      }
    });
  }, []);

  const logOut = useCallback(() => {
    localStorage.removeItem("idToken");
    localStorage.removeItem("cowfarmer");
    localStorage.removeItem("INFO");
    localStorage.removeItem("user");
    localStorage.removeItem("roles");
    localStorage.removeItem("refresh");
    dispatch({
      type: "logout"
    });
  }, []);

  const acceptTerms = useCallback(() => {
    const currentUser = JSON.parse(localStorage.getItem("user") ?? "{}");
    const modifiedUser: User = {
      ...currentUser,
      condition_1: true,
      condition_2: true
    };
    localStorage.setItem("user", JSON.stringify(modifiedUser));
    navigate(paths.home);
    dispatch({
      type: "acceptTerms"
    });
  }, [navigate]);

  const values = useMemo(() => ({
    // Metodos
    signIn,
    logOut,
    acceptTerms,
    setCowbarn,
    // Estado
    ...state
  }), [signIn, logOut, acceptTerms, setCowbarn, state]);

  return (
    <AuthContext.Provider
      value={values}
    >
      {children}
    </AuthContext.Provider>
  );
};
