import { Link } from "react-router-dom";
import { navigationConfig } from "../../../configs/navigation.config";
import { RestrictedComponent } from "../../RestrictedComponent/RestrictedComponent";
import { Icon, Text } from "@glorialabs/gl-components-module-ui";
import { FunctionComponent } from "react";
import { SubMenuType } from "../interfaces";
import ReactGA from "react-ga4";

interface IDesktopMenu {
  closeAll: () => void;
  handleSubMenu: (type: SubMenuType) => void
}

export const DesktopMenu: FunctionComponent<IDesktopMenu> = ({ closeAll, handleSubMenu }) => {
  return(
    navigationConfig.map((item) => {
      if (item.type === "item") {
        return (
          <RestrictedComponent rolesRequired={item.permissions} key={item.id}>
            <Link
              to={item.navLink}
              onClick={() => {
                ReactGA.event({
                  category: "Barra de navegación",
                  action:   "Clic elemento barra de navegación",
                  label:    item.title
                });
                closeAll();
              }}
            >
              <Text
                size="base"
                bold
                className="text-[#0A0036] tracking-wider"
              >
                {item.title}
              </Text>
            </Link>
          </RestrictedComponent>
        );
      }

      return (
        <RestrictedComponent rolesRequired={item.permissions} key={item.id}>
          <Text
            size="base"
            bold
            className="text-[#0A0036] tracking-wider flex"
            isButton={true}
            onClick={() => {
              handleSubMenu(item.id as SubMenuType);
            }}
          >
            {item.title}
            <Icon
              name="ChevronDownIcon"
              color="#0A0036"
              style={{ fontWeight: "700" }}
              className="ml-2"
            />
          </Text>
        </RestrictedComponent>
      );
    })
  );

};
