import { Text } from "@glorialabs/gl-components-module-ui";
import { RestrictedComponent } from "../../../../RestrictedComponent/RestrictedComponent";
import { Link } from "react-router-dom";
import { NavigationItem } from "../../../../../configs/interfaces";
import { FunctionComponent } from "react";

interface IMobileMenuOption{
  item: NavigationItem;
  closeAll: () => void;
}

export const MobileItemOption: FunctionComponent<IMobileMenuOption> = ({ item, closeAll }) => {
  return (
    <RestrictedComponent rolesRequired={item.permissions}>
      <Link
        to={item.navLink}
        onClick={closeAll}
      >
        <Text size="base" bold={true} className="text-[#0A0036] tracking-wider">
          {item.title}
        </Text>
      </Link>
    </RestrictedComponent>
  );
};
