import { apiServices } from "../api";
import { adapterLoan } from "./adapters/loansAdapter";
import { adapterShopping } from "./adapters/shoppingAdapter";
import {
  AssetType,
  DataActives,
  ICategoryNew,
  IHasNewNotifications,
  INew,
  ISummaryActives,
  IReadNotifications,
  ActivesPayload
} from "./interface";
import { INotificationParams } from "./request.interface";
import {
  IGetLoansResponse, IGetPurchasesResponse, ILastLoanResponse, ILastPurchaseResponse, INotificationResponse
} from "./response.interface";

export const getLastLoanByCowfarmer = async (
  cowfarmerId: number
): Promise<ILastLoanResponse> => {
  const response = await apiServices.get("/loans/getLastLoanByCowfarmer", {
    params: {
      cowfarmerId: cowfarmerId
    }
  });
  return response.data.data;
};

export const getLastPurchaseByCowfarmer = async (
  cowfarmerId: number
): Promise<ILastPurchaseResponse> => {

  const response = await apiServices.get(
    "/purchases/getLastPurchasesByCowfarmer",
    {
      params: {
        cowfarmerId: cowfarmerId
      }
    }
  );

  return response.data.data;
};

export const getLoansByCowfarmer = async (
  cowfarmerId: number
): Promise<IGetLoansResponse[]> => {
  const response = await apiServices.get("/loans/getLoansByCowfarmer", {
    params: {
      cowfarmerId: cowfarmerId
    }
  });

  return adapterLoan(response.data.data);
};

export const getPurchasesByCowfarmer = async (
  cowfarmerId: number
): Promise<IGetPurchasesResponse[]> => {
  const response = await apiServices.get(
    "/purchases/getPurchasesByCowfarmer",
    {
      params: {
        cowfarmerId: cowfarmerId
      }
    }
  );

  return adapterShopping(response.data.data);
};

export const getAssetTypes = async (): Promise<AssetType[]> => {
  const response = await apiServices.get("/physical-assets/getAllTypeProducts");
  return response.data.data;
};

export const getActives = async ({ params, signal } : ActivesPayload): Promise<DataActives> => {
  const response = await apiServices.get(
    "/physical-assets/getPhysicalAssetsByCowfarmerId",
    {
      params,
      ...(signal ? { signal } : {})
    }
  );

  return response.data.data;
};

export const getSummaryActives = async (
  cowfarmerId: number
): Promise<ISummaryActives> => {
  const response = await apiServices.get(
    "/physical-assets/getPhysicalAssetsResumenByCowfarmerId",
    {
      params: {
        cowfarmerId
      }
    }
  );

  return response.data.data;
};

export const getCategoriesNews = async (): Promise<ICategoryNew[]> => {
  const response = await apiServices.get("/news/getNewCategories");
  return response.data.data;
};

export const getNews = async (): Promise<INew[]> => {
  const response = await apiServices.get("/news/getNews");
  return response.data.data;
};

export const getDetailNew = async (slug: string): Promise<INew> => {
  const response = await apiServices.get("/news/getOneNewsByIdOrSlug", {
    params: {
      newIdOrSlug: slug
    }
  });
  return response.data.data;
};

export const getMyNotifications = async (
  notificationParams: INotificationParams
): Promise<INotificationResponse> => {
  const { id, limit, offset } = notificationParams;
  const response = await apiServices.get(
    `/notifications/getNotificationsByCowfarmer/${id}`,
    {
      params: {
        limit,
        offset
      }
    }
  );
  return response.data.data;
};

export const HasNewNotifications = async (
  id: number
): Promise<IHasNewNotifications> => {
  const response = await apiServices.get(
    `/notifications/hasUnreadWebNotifications/${id}`
  );
  return response.data.data;
};

export const readAllNotifications = async (
  id: number
): Promise<IReadNotifications> => {
  const response = await apiServices.post(
    `/notifications/readAllNotifications/${id}`
  );
  return response.data.data;
};
