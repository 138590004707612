import { paths } from "../routes/paths";
import { NavigationConfig } from "./interfaces";
import { ROLES } from "./roles.config";

const allRoles = [
  ROLES.ADMIN_COWBARN,
  ROLES.ADMIN_TI,
  ROLES.FINANCIAL_COWBARN,
  ROLES.OWNER_COWBARN,
  ROLES.VET_COWBARN
];

export const navigationConfig: NavigationConfig = [
  {
    id:          "home",
    title:       "Inicio",
    type:        "item",
    permissions: [...allRoles],
    navLink:     paths.home
  },
  {
    id:          "production",
    title:       "Mi producción",
    type:        "collapse",
    permissions: [
      ROLES.ADMIN_COWBARN,
      ROLES.ADMIN_TI,
      ROLES.OWNER_COWBARN,
      ROLES.VET_COWBARN
    ],
    children: [
      {
        type:        "item",
        id:          "production_acopio",
        title:       "Acopio",
        permissions: [
          ROLES.ADMIN_COWBARN,
          ROLES.ADMIN_TI,
          ROLES.OWNER_COWBARN,
          ROLES.VET_COWBARN
        ],
        navLink: paths.acumulation
      },
      {
        type:        "item",
        id:          "production_quality",
        title:       "Calidad",
        permissions: [
          ROLES.ADMIN_COWBARN,
          ROLES.ADMIN_TI,
          ROLES.OWNER_COWBARN,
          ROLES.VET_COWBARN
        ],
        navLink: paths.quality
      },
      {
        type:        "item",
        id:          "production_composition",
        title:       "Composición",
        permissions: [
          ROLES.ADMIN_COWBARN,
          ROLES.ADMIN_TI,
          ROLES.OWNER_COWBARN,
          ROLES.VET_COWBARN
        ],
        navLink: paths.composition
      },
      {
        type:        "item",
        id:          "certificates",
        title:       "Certificados",
        permissions: [
          ROLES.ADMIN_COWBARN,
          ROLES.ADMIN_TI,
          ROLES.OWNER_COWBARN,
          ROLES.VET_COWBARN
        ],
        navLink: paths.certificates
      },
      {
        type:        "item",
        id:          "download_results",
        title:       "Descarga de resultados",
        permissions: [
          ROLES.ADMIN_COWBARN,
          ROLES.ADMIN_TI,
          ROLES.OWNER_COWBARN,
          ROLES.VET_COWBARN
        ],
        navLink: paths.downloadProduction
      }
    ]
  },
  {
    id:          "services",
    title:       "Mis servicios",
    type:        "collapse",
    permissions: [
      ROLES.ADMIN_COWBARN,
      ROLES.ADMIN_TI,
      ROLES.OWNER_COWBARN,
      ROLES.FINANCIAL_COWBARN
    ],
    children: [
      {
        type:        "item",
        id:          "services_actives",
        title:       "Activos",
        permissions: [
          ROLES.ADMIN_COWBARN,
          ROLES.ADMIN_TI,
          ROLES.OWNER_COWBARN,
          ROLES.FINANCIAL_COWBARN
        ],
        navLink: paths.actives
      },
      {
        type:        "item",
        id:          "services_loans",
        title:       "Préstamos",
        permissions: [
          ROLES.ADMIN_COWBARN,
          ROLES.ADMIN_TI,
          ROLES.OWNER_COWBARN,
          ROLES.FINANCIAL_COWBARN
        ],
        navLink: paths.loans
      },
      {
        type:        "item",
        id:          "services_shopping",
        title:       "Compras",
        permissions: [
          ROLES.ADMIN_COWBARN,
          ROLES.ADMIN_TI,
          ROLES.OWNER_COWBARN,
          ROLES.FINANCIAL_COWBARN
        ],
        navLink: paths.shopping
      }
    ]
  },
  {
    id:          "news",
    title:       "Noticias",
    type:        "item",
    permissions: [...allRoles],
    navLink:     paths.news
  },
  {
    id:          "users",
    title:       "Usuarios",
    type:        "item",
    permissions: [ROLES.OWNER_COWBARN, ROLES.ADMIN_TI],
    navLink:     paths.users
  }
];
